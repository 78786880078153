import axios from 'axios';
const getWebViewEvent = async () => {
  return await axios.post('/api/mobile/getWebViewEvent');
};

const selectExternalEvent = async () => {
  return await axios.post('/api/mobile/selectExternalEvent');
};

export { getWebViewEvent, selectExternalEvent };
