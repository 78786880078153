<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>
                저장중인 스페이스가 있습니다.<br />
                이어서 등록하시겠습니까?
            </h3>
            <div class="single-file-btn">
                <p @click="newInsert()">아니오</p>
                <p id="color-text" @click="nextInsert()">네</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import router from '@/router';

export default defineComponent({
  name: 'addSpace2',
  props: {
    spaceType: String,
    spaceId: String,
    isRegistCard: Boolean,
    isTrialSpace: Boolean
  },
  methods: {
    linkNext (spaceId) {
      this.isRegistCard || this.isTrialSpace
        ? this.$store.commit('setTrialStatus', false)
        : this.$store.commit('setTrialStatus', true);
      this.spaceType === 'Store'
        ? router.push({ name: 'SpaceInsertStore', params: { spaceId: spaceId } })
        : router.push({ name: 'SpaceInsertCar', params: { spaceId: spaceId } });
    },
    nextInsert () {
      this.$emit('closeAddSpace');
      localStorage.setItem('spaceId', this.spaceId);
      this.linkNext(this.spaceId);
    },
    newInsert () {
      const spaceId = this.spaceId;
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/deleteMySpaceInfo', { spaceId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            localStorage.removeItem('spaceId');
            this.$emit('closeAddSpace');
            this.linkNext(null);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
});
</script>
