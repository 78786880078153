<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3 v-html="alertText"></h3>
            <div class="single-file-check">
                <p @click="linkMySpace()">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'AplayzFrontendAlertModal',
  props: {
    alertText: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    linkMySpace () {
      this.$emit('closeModal');
    }
  }
};
</script>

<style scoped>
@media all and (max-width: 500px) {
  .single-file-check p {
    font-size: 15px !important;
    color: #FF2D55;
    font-weight: 400;
  }
}
</style>
