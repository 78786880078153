<template>
  <div>
    <Aside v-if="asideStatus" @closeAside="asideStatus = false" />
    <alert-modal v-if="isAlertModal" :alertText="alertText" @closeModal="isAlertModal = false" />
    <AddSpace
      v-if="ShowAddSpace"
      :spaceType="spaceType"
      :spaceId="spaceId"
      :isRegistCard="isRegistCard"
      :isRegisteredTrialSpace="isRegisteredTrialSpace"
      :isTrialUser="isTrialUser"
      @closeAddSpace="ShowAddSpace = false"
    />

    <div class="space_pc">
      <section class="main registration">
        <div class="main_box">
          <div class="main_box_title">
            <h1>{{ this.$route.path.indexOf('product') > 0 ? '이용권 소개' : '스페이스 구독' }}</h1>
            <div class="right_wrap">
              <h2 class="cupont_text">보유중인 쿠폰이 있나요?</h2>
              <button class="add_cupon" @click="goCouponPage">
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.8 6.45H6.95V6.3V3.65H8.05V6.3V6.45H8.2H10.85V7.55H8.2H8.05V7.7V10.35H6.95V7.7V7.55H6.8H4.15V6.45H6.8ZM7.5 13.85C3.71674 13.85 0.65 10.7833 0.65 7C0.65 3.21674 3.71674 0.15 7.5 0.15C11.2833 0.15 14.35 3.21674 14.35 7C14.35 10.7833 11.2833 13.85 7.5 13.85ZM7.5 12.75C9.02499 12.75 10.4875 12.1442 11.5659 11.0659C12.6442 9.98753 13.25 8.52499 13.25 7C13.25 5.47501 12.6442 4.01247 11.5659 2.93414C10.4875 1.8558 9.02499 1.25 7.5 1.25C5.97501 1.25 4.51247 1.8558 3.43414 2.93414C2.3558 4.01247 1.75 5.47501 1.75 7C1.75 8.52499 2.3558 9.98753 3.43414 11.0659C4.51247 12.1442 5.97501 12.75 7.5 12.75Z"
                    fill="white"
                    stroke="#151515"
                    stroke-width="0.3"
                  />
                </svg>
                <span class="btn_text"> 쿠폰등록 </span>
              </button>
            </div>
          </div>
          <ul class="flex_column">
            <li class="w_100 space_description">
              <img class="space_description_img" src="/media/img/space/space_subscribe.png" alt="구독소개이미지" />
              <h1 class="space_description_title1" v-if="!isTrialUser">무제한으로 더 즐겁게</h1>
              <h1 class="space_description_title2" v-if="!isTrialUser">어플레이즈 정기구독</h1>
              <h1 class="space_description_title3" v-if="isTrialUser">어떤 공간에 음악이 필요하신가요?</h1>

              <ul class="flex_column space_description_list" :class="{ center: isTrialUser }">
                <li class="dp_flex align_center" v-if="!isTrialUser">
                  <img class="space_description_list_img" src="/media/img/space/check.png" alt="check" />
                  <h2 class="space_description_list_text1">공간 맞춤형 AI 음악 추천</h2>
                </li>
                <li class="dp_flex align_center" v-if="!isTrialUser">
                  <img class="space_description_list_img" src="/media/img/space/check.png" alt="check" />
                  <h2 class="space_description_list_text1">언제든 구독 해지 가능</h2>
                </li>
                <li class="dp_flex align_center" v-if="isTrialUser">
                  <h2 class="space_description_list_text3">공간의 유형별로 어울리는 음악을 추천해드려요.</h2>
                </li>
              </ul>
            </li>
            <li class="w_100 space_subscribe_wrap1 flex_space_between">
              <div class="flex_column">
                <h3 class="space_subscribe_title">사업자용(매장)</h3>
                <p class="space_subscribe_desc">매장 인테리어와 방문 고객을 기반으로 음악을 추천해드려요.</p>
              </div>
              <div class="align_center">
                <button class="space_subscribe_btn" @click="insertSpace('Store')">
                  {{ isTrialUser ? '체험하기' : '월 9,000원' }}
                </button>
              </div>
            </li>
            <li class="w_100 space_subscribe_wrap2 flex_space_between">
              <div class="flex_column">
                <h3 class="space_subscribe_title">개인용(차량)</h3>
                <p class="space_subscribe_desc">
                  나만의 AI 음악 라디오, 래디오에서 주행 목적별 음악을 무료로 즐겨보세요!
                </p>
              </div>
              <div class="align_center">
                <button class="radio_app_btn" @click="clickAppStore">
                  <!--{{ isTrialUser ? '체험하기' : '월 2,000원' }}-->
                  {{ '앱 다운로드' }}
                </button>
              </div>
            </li>
          </ul>
          <div class="registration_bottom" v-if="!isTrialUser">
            <h5 class="notice_text">유의사항</h5>
            <ul>
              <li>
                <span></span>
                <p>PC, 안드로이드폰, 아이폰, 태블릿 등에서 이용 가능합니다.</p>
              </li>
              <li v-if="isTrialUser">
                <span></span>
                <p>
                  결제 수단의 정지/한도초과/정보변경 등의 사유로 결제상태가 변경될 경우 결제 및 서비스 제공이 중단되며,
                  정상으로 전환되거나 신규 결제수단 추가하시면 다시 결제 및 서비스가 시작됩니다.
                </p>
              </li>
              <li v-if="!isTrialUser">
                <span></span>
                <p>
                  매월 결제일 기준 7일 이내 이용내역이 없는 경우, 마이스페이스 메뉴에서 구독 해지 신청 가능하며 전액
                  환불됩니다. (3영업일 이내)
                </p>
              </li>
              <li>
                <span></span>
                <p>계정별 중복 로그인은 가능하나, 하나의 스페이스는 동시에 하나의 기기에서만 재생 가능합니다.</p>
              </li>
              <li v-if="!isTrialUser">
                <span></span>
                <p>스페이스별로 과금이 부과됩니다.</p>
              </li>
              <li v-if="!isTrialUser">
                <span></span>
                <p>상기 금액은 VAT 별도 금액입니다.</p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
    <!-- 모바일 -->
    <div class="space_mo">
      <header class="header mo_header">
        <div class="header_box">
          <div class="logo">
            <a class="back_menu">
              <img src="/media/images/icon_back_24.svg" @click="clickPre()" />
              <h2>스페이스 구독</h2>
            </a>
          </div>
          <div class="right_menu">
            <div class="alarm_box" @click="mobileAlarm">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.02759 19.7773C9.02759 20.5181 9.46002 21.9996 11.1897 21.9996C12.9195 21.9996 13.3519 20.5181 13.3519 19.7773"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.00024 18.666H20.3786"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.62183 18.1113V10.3335C3.62183 7.92613 5.13534 3.11133 11.1894 3.11133C17.2434 3.11133 18.7569 8.2965 18.7569 10.8891V18.1113"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <ellipse
                  v-if="$store.state.noticeCount !== 0"
                  cx="17.6756"
                  cy="6.44444"
                  rx="4.32431"
                  ry="4.44444"
                  fill="#FF1150"
                />
              </svg>
            </div>
            <div class="toggle" @click="showAside">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2231_20993)">
                  <path
                    d="M24 20L6 20L6 18L24 18L24 20ZM24 13L12 13L12 11L24 11L24 13ZM24 6L6 6L6 4L24 4L24 6Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2231_20993">
                    <rect width="24" height="24" fill="white" transform="translate(24 24) rotate(180)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
      </header>
      <section class="main product">
        <div class="main_box space_subscribe">
          <div class="mo_coupon_box">
            <h2 class="mo_coupon_text">보유중인 쿠폰이 있나요?</h2>
            <button class="add_coupon" @click="goCouponPage">
              <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.8 6.45H6.95V6.3V3.65H8.05V6.3V6.45H8.2H10.85V7.55H8.2H8.05V7.7V10.35H6.95V7.7V7.55H6.8H4.15V6.45H6.8ZM7.5 13.85C3.71674 13.85 0.65 10.7833 0.65 7C0.65 3.21674 3.71674 0.15 7.5 0.15C11.2833 0.15 14.35 3.21674 14.35 7C14.35 10.7833 11.2833 13.85 7.5 13.85ZM7.5 12.75C9.02499 12.75 10.4875 12.1442 11.5659 11.0659C12.6442 9.98753 13.25 8.52499 13.25 7C13.25 5.47501 12.6442 4.01247 11.5659 2.93414C10.4875 1.8558 9.02499 1.25 7.5 1.25C5.97501 1.25 4.51247 1.8558 3.43414 2.93414C2.3558 4.01247 1.75 5.47501 1.75 7C1.75 8.52499 2.3558 9.98753 3.43414 11.0659C4.51247 12.1442 5.97501 12.75 7.5 12.75Z"
                  fill="white"
                  stroke="#151515"
                  stroke-width="0.3"
                />
              </svg>
              <span class="btn_text"> 쿠폰등록 </span>
            </button>
          </div>
          <ul class="flex_column">
            <li class="w_100 space_description">
              <img class="space_description_img" src="/media/img/space/mo_space_subscribe.png" alt="구독소개이미지" />
              <h1 class="space_description_title1" v-if="!isTrialUser">무제한으로 더 즐겁게</h1>
              <h1 class="space_description_title2" v-if="!isTrialUser">어플레이즈 정기구독</h1>
              <h1 class="space_description_title3" v-if="isTrialUser">어떤 공간에 음악이 필요하신가요?</h1>

              <ul class="flex_column space_description_list" :class="{ center: isTrialUser }">
                <li class="dp_flex align_center" v-if="!isTrialUser">
                  <img class="space_description_list_img" src="/media/img/space/check.png" alt="check" />
                  <h2 class="space_description_list_text1">공간 맞춤형 AI 음악 추천</h2>
                </li>
                <li class="dp_flex align_center space_description_list_wrap" v-if="!isTrialUser">
                  <img class="space_description_list_img" src="/media/img/space/check.png" alt="check" />
                  <h2 class="space_description_list_text2">언제든 구독 해지 가능</h2>
                </li>
                <li class="dp_flex align_center" v-if="isTrialUser">
                  <h2 class="space_description_list_text3">공간의 유형별로 어울리는 음악을 추천해드려요.</h2>
                </li>
              </ul>
            </li>
            <li class="w_100 space_subscribe_wrap1 flex_space_between">
              <div class="flex_column">
                <h3 class="space_subscribe_title">사업자용(매장)</h3>
                <p class="space_subscribe_desc">
                  매장 인테리어와 방문 고객을 <br />
                  기반으로 음악을 추천해드려요.
                </p>
              </div>
              <div class="align_center">
                <button class="space_subscribe_btn" @click="insertSpace('Store')">
                  {{ isTrialUser ? '체험하기' : '월 9,000원' }}
                </button>
              </div>
            </li>
            <li class="w_100 space_subscribe_wrap2 flex_space_between">
              <div class="flex_column">
                <h3 class="space_subscribe_title">개인용(차량)</h3>
                <p class="space_subscribe_desc">
                  나만의 AI 음악 라디오, 래디오에서 <br />
                  주행 목적별 음악을 무료로 즐겨보세요!
                </p>
              </div>
              <div class="align_center">
                <button class="radio_app_btn" @click="clickAppStore">
                  <!--{{ isTrialUser ? '체험하기' : '월 2,000원' }}-->
                  {{ '앱 다운로드' }}
                </button>
              </div>
            </li>
          </ul>
          <ul v-if="!isTrialUser" class="flex_space_between subscribe_notice_wrap" :class="{ not_open: !isOpenNotice }">
            <li class="subscribe_notice_text">이용권 구독 유의사항</li>
            <li class="align_center" @click="isOpenNotice = !isOpenNotice">
              <img
                class="subscribe_notice_arrow"
                :class="{ on: isOpenNotice }"
                src="/media/img/space/arrow.png"
                alt="화살표"
              />
            </li>
          </ul>
          <transition name="slide-updown">
            <div class="registration_bottom" v-if="isOpenNotice">
              <h5>유의사항</h5>
              <ul>
                <li>
                  <span></span>
                  <p>PC, 안드로이드폰, 아이폰, 태블릿 등에서 이용 가능합니다.</p>
                </li>
                <li v-if="!isTrialUser">
                  <span></span>
                  <p>
                    결제 수단의 정지/한도초과/정보변경 등의 사유로 결제상태가 변경될 경우 결제 및 서비스 제공이
                    중단되며, 정상으로 전환되거나 신규 결제수단 추가하시면 다시 결제 및 서비스가 시작됩니다.
                  </p>
                </li>
                <li v-if="!isTrialUser">
                  <span></span>
                  <p>
                    매월 결제일 기준 7일 이내 이용내역이 없는 경우, 마이스페이스 메뉴에서 구독 해지 신청 가능하며 전액
                    환불됩니다. (3영업일 이내)
                  </p>
                </li>
                <li>
                  <span></span>
                  <p>계정별 중복 로그인은 가능하나, 하나의 스페이스는 동시에 하나의 기기에서만 재생 가능합니다.</p>
                </li>
                <li v-if="!isTrialUser">
                  <span></span>
                  <p>스페이스별로 과금이 부과됩니다.</p>
                </li>
                <li v-if="!isTrialUser">
                  <span></span>
                  <p>상기 금액은 VAT 별도 금액입니다.</p>
                </li>
              </ul>
              <ul class="appstore_price_policy" v-if="pricePolicy.list.length > 0">
                <li>
                  <h5 class="appstore_price_policy_text">{{ pricePolicy.title }}</h5>
                </li>
                <li class="flex_column">
                  <ul class="flex_column w_100 appstore_price_policy_list_wrap">
                    <li class="dp_flex" v-for="(item, index) in pricePolicy.list" :key="'policy' + index">
                      <div class="appstore_price_policy_dot"></div>
                      <p class="appstore_price_policy_text" v-html="item"></p>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </transition>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import AddSpace from '@/components/modal/Main/AddSpace2.vue';
import AlertModal from '@/components/modal/Space/AlertModal.vue';
import Aside from '@/layout/aside/Aside.vue';
import router from '@/router';
import axios from '@/service/axios';
import { defineComponent } from '@vue/composition-api';
import VueCookies from 'vue-cookies';
import { getMySpaceList } from '@/service/api/profileApi';
import { checkMobile } from '@/utils/Utils';
export default defineComponent({
  name: 'insertSpace',
  data() {
    return {
      isAlertModal: false,
      alertText: '',
      asideStatus: false,
      isRegistCard: false,
      ShowAddSpace: false,
      isRegisteredTrialSpace: false,
      isTrialUser: false,
      spaceId: '',
      spaceType: '',
      month: '',
      isTrialPrice: true,
      isOpenNotice: false,
      pricePolicy: {
        key: '',
        title: '',
        list: []
      },
      pricePolicyList: [
        {
          key: 'android',
          title: 'Google Play 구매/해지 안내',
          list: [
            '해지 신청은 Google Play의 정기 결제 페이지에서 가능하며, 중도 해지 기능은 제공되지 않습니다.',
            '구매 가격에는 부가세가 포함되어있습니다. <br> (해외원화결제 수수료는, 별도로 부가될 수 있습니다.)',
            `구글의 인앱 결제를 이용하시는 경우, 어플레이즈에서 안내하는 ‘결제일'보다 하루 먼저 정기결제가 될 수 있습니다.`
          ]
        },
        {
          key: 'ios',
          title: 'App Store 구매/해지 안내',
          list: [
            'App Store 결제는, 현재 사용하고 있는 App Store 계정을 통해 결제됩니다.',
            '구매 가격에는 부가세가 포함되어있습니다.',
            '결제 금액에 대한 확인 및 해지/환불은 APPLE을 통해 가능합니다. 결제내역 및 환불 문의는 APPLE 고객센터 (080-333-4000)으로 부탁드립니다.'
          ]
        }
      ]
    };
  },
  components: {
    Aside,
    AddSpace,
    'alert-modal': AlertModal
  },
  created() {
    const os = checkMobile();
    const findPricyIndex = this.pricePolicyList.findIndex(item => item.key === os);
    if (findPricyIndex > -1) {
      this.pricePolicy = this.pricePolicyList[findPricyIndex];
    }

    if (Object.keys(this.$route.params).length > 0) {
      const { isTrialPrice } = this.$route.params;
      this.isTrialPrice = isTrialPrice;
    }
    this.checkRegistCard();
    this.checkTrialSpaceCnt();
    this.checkIsTrialUser();
  },

  methods: {
    goCouponPage() {
      this.$router.push({ name: 'Coupon' }).catch(() => {});
    },
    getMonth() {
      const today = new Date();
      this.month = today.getMonth() + 1;
    },
    checkRegistCard() {
      const userId = VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/product/getMyCardInfo', { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            result != null ? (this.isRegistCard = true) : (this.isRegistCard = false);
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    showAside() {
      this.asideStatus = true;
    },
    mobileAlarm() {
      this.$router.push({ name: 'Notice0' });
    },
    linkPlayer() {
      const spaceId = VueCookies.get('spaceId');
      if (spaceId != null) {
        router.push({ name: 'Player' });
      } else {
        router.push({ name: 'Main' });
      }
    },
    clickPre() {
      history.back();
    },

    /**
     * * 카드를 등록 했거나 체험용 스페이스가 이미 존재한다면 trialStatus의 값을 false 아니면 true
     */
    linkNext(spaceType) {
      this.isRegistCard || this.isRegisteredTrialSpace
        ? this.$store.commit('setTrialStatus', false)
        : this.$store.commit('setTrialStatus', true);
      spaceType === 'Store'
        ? router.push({ name: 'SpaceInsertStore', params: { spaceId: null } })
        : router.push({ name: 'SpaceInsertCar', params: { spaceId: null } });
    },
    clickAppStore() {
      window.open(`https://raidio.app.link/default`, 'blank');
    },

    insertSpace(spaceType) {
      localStorage.removeItem('spaceId');
      const userId = VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/checkSpace', { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            let sCount = -1;
            if (result.length > 0) {
              sCount = result.filter(e => e === 'S').length;
            }
            if (sCount === result.length) {
              // 보유 스페이스가 전부 S인 경우
              axios
                .post('/api/profile/getRecentSpaceInfoByUserByType', { userId, spaceType }, { headers })
                .then(res2 => {
                  // 타입별 최근 등록 스페이스ID 가져오기
                  if (res2.data.resultCd === '0000') {
                    this.spaceId = res2.data.result;
                    this.spaceType = spaceType;
                    this.ShowAddSpace = true;
                  }
                })
                .catch(err2 => {
                  // 등록된 스페이스가 없으면 등록페이지 이동
                  const result2 = err2.response.data;
                  if (result2.resultCd === '400' && result2.resultMsg === '조회된 데이터가 없습니다.') {
                    this.linkNext(spaceType);
                  }
                });
            } else {
              this.linkNext(spaceType);
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    /**
     * * 체험용 스페이스의 등록 여부 체크
     * * 이미 등록된 체험용 스페이스가 있다면 true 아니면 false
     */
    checkTrialSpaceCnt() {
      const userId = VueCookies.get('userId');

      axios
        .post('/api/profile/checkTrialSpace', { userId })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.isRegisteredTrialSpace = res.data.result;
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    async getMySpaceListCount() {
      try {
        const userId = this.$cookies.get('userId');

        const { data } = await getMySpaceList(userId, 99);
        const { result, resultCd } = data;
        if (resultCd === '0000') {
          const { spaceInfoListInfo } = result;
          return spaceInfoListInfo.filter(item => item.spacePayAt !== 'S').length;
        } else {
          return 0;
        }
      } catch (error) {
        return 0;
      }
    },
    /**
     * * 무료 체험 기간 해당 여부
     */
    checkIsTrialUser() {
      const userId = VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      axios
        .post('/api/user/getUserCase', { userId }, { headers })
        .then(async res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            if (result.trialYn === 'Y') {
              const count = await this.getMySpaceListCount();
              if (count > 0) {
                this.isTrialUser = false;
              } else {
                this.isTrialUser = true;
              }
            } else {
              this.isTrialUser = false;
            }
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
});
</script>

<style scoped src="@/assets/css/space/spaceinsert.css"></style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
