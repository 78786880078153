import store from "@/store";
import axios from "@/service/axios";
import VueCookies from "vue-cookies";

export async function createMyChannel () { // 채널 생성
  const userId = VueCookies.get("userId");
  const spaceId = VueCookies.get("spaceId");
  const aToken = VueCookies.get("aToken");
  const headers = { "X-AUTH-TOKEN": aToken };
  try {
    const response = await axios.post("/api/webcast/createMyChannel", { userId, spaceId }, { headers });
    const result = response.data.result;
    store.commit("setChannelId", result.channelId);
    store.commit("setChannelUrl", result.url);
    store.commit("player/setOriginalUrl", result.url);
    return result;
  } catch (err) {
    if (err.response) {
      const { data, status } = err.response;
      const { resultMsg } = data;
      return {
        status,
        data,
        resultMsg
      };
    }

    return null;
  }
}

export async function joinRecomChannel (dstChannelId) { // 채널 참여
  const userId = VueCookies.get("userId");
  const spaceId = VueCookies.get("spaceId");
  const channelId = store.state.channelId;
  const aToken = VueCookies.get("aToken");
  const headers = { "X-AUTH-TOKEN": aToken };
  try {
    const response = await axios.post("/api/webcast/joinRecomChannel", { userId, spaceId, channelId, dstChannelId }, { headers });
    const result = response.data.result;
    if (result.channelName === '사용할 수 없는 채널입니다.') return false;
    store.commit("setChannelId", result.channelId);
    store.commit("setChannelUrl", result.url);
    return result;
  } catch (e) {
    return console.error(e);
  }
}

export async function leaveChannel () { // 채널 이탈
  store.commit("setPlay", false); // 재생값 false로 변경해야 플레이어 페이지 진입 시 createMyChannel 호출
  const userId = VueCookies.get("userId");
  const spaceId = VueCookies.get("spaceId");
  const channelId = store.state.channelId;
  const aToken = VueCookies.get("aToken");
  const headers = { "X-AUTH-TOKEN": aToken };
  try {
    const response = await axios.post("/api/webcast/leaveChannel", { userId, spaceId, channelId }, { headers });
    const result = response.data.result;
    store.commit("setChannelId", '');
    store.commit("setChannelUrl", null);
    return result;
  } catch (e) {
    // leaveChannel 에러 발생 시 채널 아이디 초기화
    store.commit("setChannelId", '');
    store.commit("setChannelUrl", null);
    return console.error(e);
  }
}
